import React from 'react';
// Pieces imports
import { SEO, Heading, Box } from 'pieces';
// sections

const RenovierungenPage = () => (
  <>
    <SEO title='Renovierungen' />
    <Box sx={{ minHeight: '100vh' }}>
      <Heading>Renovierungen</Heading>
    </Box>
  </>
);

export default RenovierungenPage;
